import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faGithub, faLinkedin, faXTwitter} from '@fortawesome/free-brands-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import DP from './images/photoVAVILALA.JPG';
import '../App.css';


const Sidebar = ({scrollY,user}) => {

    return(
        <aside className={`w-full  md:w-full lg:sticky lg:w-2/6 lg:h-screen sm:px-8 pt-10 overflow-y-auto ${scrollY>0 ? 'lg:sticky lg:top-0' : ''}`}>
            <div class="profileimagecontainer">
         
                <img src={DP} alt="Chandan Vavilala" className="logo w-48 h-48 rounded-full bg-gray-700" />
            </div>

            <div className="flex items-center justify-center space-x-4 px-2 py-2">
                <div class="text-center ">
                    <h1 className="text-cyan-400 font-extrabold  text-xl sm:text-4xl elevate rounded-lg pt-10 sm:pt-10 pb-5">{user.name}</h1>
                    <p className="text-cyan-600 text-xl font-bold"><i>{user.currentRole}</i></p>
                </div>
            </div>


            <nav className="mt-10 ">
                <ul className="text-white flex flex-wrap justify-center">
                <li><a href={user.githubLink} className="socialaccounts bg-black"><FontAwesomeIcon icon={faGithub}/> GitHub</a></li>
                <li><a href={user.linkedinLink} className="socialaccounts bg-blue-600"><FontAwesomeIcon icon={faLinkedin}/> LinkedIn</a></li>
                <li><a href={user.xLink}className="socialaccounts bg-black"><FontAwesomeIcon icon={faXTwitter}/></a></li>
                </ul>
                <a href="/resume.pdf" target="_blank" className="text-blue-500 font-extrabold justify-self-center block text-center mt-10">
                    View Full Resume <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </a>
                <div class=" rounded-3xl m-8 bg-slate-950">
                <ul class="flex flex-wrap py-6 text-orange-500 justify-center font-sans">
                    <li class="px-2"><a href="#about">About</a></li>
                    <li class="px-2"><a href="#experience">Experience</a></li>
                    <li class="px-2"><a href="#education">Education</a></li>
                    <li class="px-2"><a href="#projects">Projects</a></li>
                    <li class="px-2"><a href="#skills">Skills</a></li>
                    <li class="px-2"><a href="#connect">Contact</a></li>
                </ul>
                </div>
            </nav>
        </aside> 
);
};

export default Sidebar;
