import React from "react"
import '../App.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshakeAngle } from "@fortawesome/free-solid-svg-icons";

const Footer = ({user}) =>{
    return(
        <div class = "gradient-bg text-center items-center py-10" id="connect">
            <p class="text-3xl text-blue-400">Get In Touch</p>
            <p class = "text-xl text-blue-100 mx-2 lg:mx-auto pt-4 pb-10">I'm currently seeking new opportunities. Please feel free to send any leads my way!</p>
            <a href={`mailto:${user.email}`} class="p-3 text-white border border-green-600 font-mono rounded-3xl">Say Hello <FontAwesomeIcon icon={faHandshakeAngle} /></a>
            <p class="text-green-300 pt-10 font-mono"> Designed and Built by Chandan Vavilala</p>
        
        </div>
    )
}

export default Footer;