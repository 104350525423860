import React from "react";
import '../App.css';

const Education =({user})=>{
    return (
        <div id="education" class="pt-10">
        <h2 className="text-2xl font-bold mb-4 my-10 ">Education</h2>
       {user.education.map((education, index) => (

            <div key={index} className="space-y-4 sm:px-8 px-6">
            <div class="lg:flex py-3">
                <div class="w-34 pr-12 pb-2">
                    <a class="text-sm">{education.startDate} - {education.endDate}</a>
                </div>
                <div className="bg-blue-700 py-4 px-5 rounded-lg">
                    <h3 className="text-lg font-bold">{education.courseName}</h3>
                    <a class="text-lg">{education.collegeName}</a>
                    <p>{education.location}</p>
                </div>
            </div>
            </div>

        ))}
        </div>
    )
}

export default Education;