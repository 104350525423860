import React from 'react';
import './App.css';

// import axios from'axios';


import { useState, useEffect, useLayoutEffect } from 'react';

import Sidebar from './components/Sidebar';
import Timeline from './components/Timeline';
import Technologies from './components/Technologies';
import Footer from './components/Footer';

function App() {

  const [scrollY, setScrollY] = useState(0);
  const [userInfo, setUserInfo] = useState();
  

  useLayoutEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

const [user, setUser] = useState({
  "_id": {"$oid":"668b937986a70d19e48620e6"},
  "id": {"$numberInt":"1"},
  "name": "Poorna Chandan Reddy Vavilala",
  "currentRole": "Software Developer",
  "githubLink": "https://github.com/chandan-vavilala",
  "linkedinLink": "https://linkedin.com/in/chandan-vavilala",
  "xLink": "https://x.com/Chandan_channu",
  "email": "chandanreddyvavilala@gmail.com",
  "about": "With a solid foundation in both academic achievement and practical experience, I am a dedicated tech enthusiast who recently completed a Master's degree in Applied Computer Science and Engineering. My professional journey spans roles ranging from software development internships to graduate assistantships, where I honed my skills in web and mobile application development using technologies such as HTML, CSS, JavaScript, Swift, and various frameworks like React.js and Node.js. I thrive on tackling complex challenges and am eager to leverage my skills in a dynamic role within a reputed organization, contributing effectively towards achieving its goals.",
  "experience": [
    {
      "startDate": "Jul'24",
      "endDate": "Present",
      "position": "Software Developer Intern",
      "company": "Sunmerge Technologies Inc",
      "description": "In my current role as an intern, I am responsible for evaluating and assessing software to ensure it meets quality standards and aligns with project requirements. I actively contribute to analyzing systems design and architectures, identifying opportunities for improvement and optimization. Additionally, I am involved in evaluating and reporting on business automation processes, enhancing operational efficiency and streamlining workflows. This internship provides me with valuable hands-on experience in software evaluation, systems analysis, and business process optimization within a dynamic professional setting.",
      "location": "Piscataway, NJ, United States",
      "technologies":["HTML","JavaScript",".NET","Oracle"]
    },
    {
      "startDate": "Jan'24",
      "endDate": "May'24",
      "position": "Graduate Assistant - Mobile Computing iOS",
      "company": "Northwest Missouri State University",
      "description": "Successfully contributed to various mobile computing iOS projects, specializing in designing and developing applications using Swift. I collaborated closely with project teams to ensure their understanding and alignment. With a strong grasp of UI/UX principles, I optimized user interfaces for intuitive interaction.",
      "location": "Maryville, MO, United States",
      "technologies":["Swift","Lottie","Firebase"]
    },
    {
      "startDate": "May'23",
      "endDate": "Dec'23",
      "position": "Graduate Assistant - Web Applications Development",
      "company": "Northwest Missouri State University",
      "description": "As a graduate assistant for the web applications development course, I played a crucial role in guiding students through the intricacies of modern web development. I collaborated closely with the instructor to refine curriculum content and led hands-on lab sessions where I assisted students in mastering programming languages, frameworks, and UI/UX principles. I provided valuable feedback on assignments, conducted code reviews, and fostered a supportive learning environment to nurture students' skills in building robust and user-friendly web applications. My contributions ensured that students gained practical, industry-relevant experience essential for their future careers in web development.",
      "location": "Maryville, MO, United States",
      "technologies":["HTML","CSS","Markdown","Render","Java","PUG","JavaScript","Github"]
    },
    {
      "startDate": "June'22",
      "endDate": "Dec'22",
      "position": "Associate Software Developer",
      "company": "GQBAY Software Pvt Ltd",
      "description": "As an associate software developer, I contributed to the design, development, and maintenance of software applications within a collaborative team environment. Leveraging my foundational knowledge and skills acquired through education and practical experience, I actively participated in coding, testing, and debugging to ensure robust functionality and optimal performance of applications. I collaborated closely with senior developers to implement technical solutions, adhere to coding standards, and integrate new technologies as part of continuous learning and improvement. ",
      "location": "Gachibowli, TG, India",
      "technologies":["HTML","CSS","Java","JavaScript"]
    },
    {
      "startDate": "Feb'22",
      "endDate": "May'22",
      "position": "Software Developer Intern",
      "company": "GQBAY Software Pvt Ltd",
      "description": "I actively contributed to diverse projects aimed at enhancing my technical skills and industry knowledge. I collaborated closely with senior developers to design and implement software solutions, gaining hands-on experience in programming languages, frameworks, and development methodologies. My role involved troubleshooting and resolving issues to improve application performance while adhering to best practices in software design and development.Through this internship, I honed my understanding of agile development processes, preparing me for a sucessful carrer in software engineering.",
      "location": "Gachibowli, TG, India",
      "technologies":["HTML","CSS","Java","JavaScript"]
    }
  ],
  "education": [
    {
      "startDate": "Jan 2023",
      "endDate": "May 2024",
      "courseName": "Masters in Applied Computer Science",
      "collegeName": "Northwest Missouri State University",
      "location": "Maryville, MO, United States"
    },
    {
      "startDate": "Aug 2018",
      "endDate": "Apr 2022",
      "courseName": "Bachelors in Computer Science and Information Systems",
      "collegeName": "MVJ College of Engineering",
      "location": "Whitefield Bangalore, India"
    }
  ],
  "projects": [
    {
      "thumbnail": "./images/mern.jpg",
      "projectName": "Perfomatrics for School Evaluation",
      "description": "Developed a web application for school evaluation using React, Node.js, MongoDB, and other technologies.",
      "technologies": ["React.js","Express.js","Node.js","MongoDB","Firebase","Python","Tailwind CSS","Font Awesome Icons","Chart.js"]
    },
    {
      "thumbnail": "./images/swift.jpg",
      "projectName": "NWMSU Employment Application - iOS",
      "description": "Created an iOS application for managing employment applications using Swift and Firebase.",
      "technologies": ["Swift","Firebase","Lottie"]
    },
    {
      "thumbnail": "./images/tableau.jpg",
      "projectName": "Data Visualization - Tableau",
      "description": "Designed interactive data visualizations using Tableau and Tableau Prep.",
      "technologies": ["Tableau","Tableau Prep"]
    },
    {
      "thumbnail":"./images/mysql.jpg",
      "projectName": "MySQL Database Management",
      "description": "Managed and optimized MySQL databases for various projects.",
      "technologies": ["MySQL","PhpMyAdmin","HTML","CSS"]
    },{
      "thumbnail":"./images/htmlcss.jpg",
      "projectName": "Static E-Commerce Website",
      "description": "Developed a static e-commerce website that helps businesses reach their customers online.",
      "technologies": ["HTML","CSS","BootStrap"]
    }
  ],
  "workedtechnologies": ["React","Vue.js","Node.js","HTML5","CSS3","Python","Java","PHP","Markdown","GitHub","GitLab","FontAwesome","Figma"]
});



  return (
  <div>
      <div className="lg:flex flex-row mx-auto gradient-bg">
     
          <Sidebar scrollY={scrollY} user={user} />
          <Timeline user={user}/>
      </div>
          <Technologies user={user}/>
          <Footer user={user}/>
  </div>
  );
}


// Chandan Reddy Vavilala
export default App;
